import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import './index.css';

import './lib/bootstrap/css/mvit-global.min.css';
import './lib//fonts-canbiz/stylesheet.css';
import './lib/ionicons/css/ionicons.min.css';
import './lib/animate/animate.min.css';
import './styles/styles.css';
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// import { createRoot } from 'react-dom/client';
// const container = document.getElementById('app');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App tab="home" />);

