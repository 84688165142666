import * as React from "react";
import Menu from "@mui/material/Menu";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";


export default function MenuPopupState() {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const langSegment = pathSegments[1];
  const navigate = useNavigate();

  const handleChange = (nextLocale: string) => {
    const newPath = `/${nextLocale}${location.pathname.substring(
      langSegment.length + 1
    )}`;
    navigate(newPath);
    window.location.reload(); // This forces a reload after navigation
  };
  
  //hamberger code start

  const [showModal, setShowModal] = React.useState(false);
  let handleSmoothScroll = (e, targetId) => {
    e.preventDefault();
    const target = document.querySelector(targetId);
    if (target) {
      let top_space = 0;
      const header = document.getElementById("headerData");
      if (header) {
        top_space = header.offsetHeight;
        if (!header.classList.contains("header-scrolled")) {
          top_space -= 20;
        }
      }
      window.scroll({
        top: target.offsetTop - top_space,
        behavior: "smooth",
      });
    }
  };
  //end

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <img
              {...bindTrigger(popupState)}
              className="h-full1 w-full1 shrink-0 object-contain"
              alt=""
              src="/img/hemburge1.png"
            />
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                sx={{ fontFamily: "Afacad", fontSize: "18px" }}
                onClick={popupState.open}
                component={Link}
                to="/home"
              >
               Home
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "Afacad", fontSize: "18px" }}
                onClick={(e) => {
                  handleSmoothScroll(e, "#features");
                }}
              >
               Features
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "Afacad", fontSize: "18px" }}
                onClick={(e) => {
                  handleSmoothScroll(e, "#tutorials");
                }}
              >
                {" "}
                About
              </MenuItem>

              <MenuItem
                sx={{ fontFamily: "Afacad", fontSize: "18px" }}
                onClick={function (event) {
                  setShowModal(true);
                  // popupState.close;
                }}
              >
                {" "}
                Pricing
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "Afacad", fontSize: "18px" }}
                onClick={(e) => {
                  handleSmoothScroll(e, "#howItWorks");
                }}
              >
                {" "}
                Customer Reviews
              </MenuItem>
             

            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
}
