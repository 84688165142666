// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { config } from "../config";
import MobileMenu from "../components/Mobilemenu";
import "../styles/global.css";
import "../styles/index.css";
const Home = () => {
  return (
    <>
      <div className="canbiz-landing-page-desktop">
        <div className="hero-image"></div>
        <header className="header">
          <div className="logo-background">
            <img
              className="logo-background-child"
              loading="lazy"
              alt=""
              src="./img/group-112@2x.png"
            />

            <div className="brand-name">
              <img
                className="canbizz-icon"
                loading="lazy"
                alt=""
                src="./img/canbizz.svg"
              />
            </div>
          </div>
          <div className="portfolio">Portfolio</div>
          <div className="navigation">
            <div className="nav-links">
              <nav className="copyright">
                <a href="#" className="features">
                  Features
                </a>
                <a href="#" className="about">
                  About
                </a>
                <a href="#" className="pricing">
                  Pricing
                </a>
                <a href="#" className="customer-reviews">
                  Customer Reviews
                </a>
              </nav>
              <div className="nav-link-separator"></div>
            </div>
          </div>
          <div className="call-to-action">
            <button className="demo-button-container">
              <a href="#" className="get-a-free">
                Get a Free Demo
              </a>
            </button>
            <button
              className="login-button-container"
              id="loginButtonContainer"
            >
              <a href="#" className="login">
                Login
              </a>
            </button>
          </div>
          <div className="mobileDevice">
            <MobileMenu />
          </div>
        </header>
        <section className="hero-content">
          <div className="hero-title-container-parent">
            <div className="hero-title-container">
              <div className="hero-title">
                <div className="hero-title-inner">
                  <div className="canbiz-a-business-model-canv-wrapper">
                    <div className="canbiz-a">
                      Canbiz - A Business Model Canvas Portal
                    </div>
                  </div>
                </div>
                <h1 className="access-our-business">
                  Access Our Business Model and Transform Your Vision into
                  Reality
                </h1>
                <div className="hero-button">
                  <button
                    className="hero-button-container"
                    id="heroButtonContainer"
                  >
                    <div className="get-started">Get Started</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="dashboard-preview">
              <div className="dashboard-preview-child"></div>
              <div className="canbiz-dashboard-list-view">
                <div className="header-bg-parent">
                  <div className="header-bg"></div>
                  <div className="dashboard-title-container">
                    <img
                      className="dashboard-title-container-child"
                      loading="lazy"
                      alt=""
                      src="./img/group-112-1@2x.png"
                    />

                    <div className="dashboard-brand">
                      <img
                        className="canbizz-icon1"
                        loading="lazy"
                        alt=""
                        src="./img/canbizz-1.svg"
                      />
                    </div>
                  </div>
                  <div className="portfolio1">Portfolio</div>
                  <div className="dashboard-content">
                    <div className="dashboard-card">
                      <div className="dashboard">Dashboard</div>
                      <div className="dashboard-card-type">
                        <div className="business-model-canvas">
                          Business Model Canvas
                        </div>
                        <img
                          className="empty-icon"
                          alt=""
                          src="./img/empty.svg"
                        />
                      </div>
                      <div className="test">Test</div>
                    </div>
                  </div>
                  <div className="dashboard-footer">
                    <div className="dashboard-user">
                      <img
                        className="empty-icon1"
                        alt=""
                        src="./img/frame-1.svg"
                      />

                      <div className="dashboard-credits">
                        <div className="credits-05">Credits 05</div>
                      </div>
                    </div>
                    <div className="dashboard-avatar">
                      <img
                        className="empty-icon2"
                        alt=""
                        src="./img/frame-2.svg"
                      />
                    </div>
                    <img
                      className="avtar-icon"
                      alt=""
                      src="./img/avtar@2x.png"
                    />
                  </div>
                </div>
                <div className="canvas-container-wrapper">
                  <div className="canvas-container">
                    <div className="canvas-header">
                      <div className="canvas-title-container">
                        <div className="canvas-title">
                          <div className="canvas-name">
                            <div className="business-model-canvas1">
                              Business Model Canvas
                            </div>
                          </div>
                          <div className="section-title">/</div>
                          <div className="section-title-wrapper">
                            <a className="section-title1">Sports Wear</a>
                          </div>
                        </div>
                      </div>
                      <div className="canvas-content">
                        <div className="progress-bar-container">
                          <div className="progress-bar">
                            <div className="progress-value-container">
                              <div className="progress-percentage">
                                Overall Progress
                              </div>
                            </div>
                            <input
                              className="remaining-progress-container"
                              placeholder="76%"
                              type="text"
                            />
                          </div>
                          <div className="progress-bar1">
                            <div className="current-progress"></div>
                          </div>
                        </div>
                        <div className="canvas-actions">
                          <div className="new-canvas-button">
                            <div className="new-canvas-button-container">
                              <img
                                className="empty-icon3"
                                alt=""
                                src="./img/frame-3.svg"
                              />
                            </div>
                            <div className="create-new-canvas">
                              Create New Canvas
                            </div>
                            <div className="more-actions">
                              <div className="more-actions-container">
                                <img
                                  className="empty-icon4"
                                  alt=""
                                  src="./img/frame-4.svg"
                                />

                                <div className="more-actions-button">
                                  <div className="empty">5</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-model">
                      <div className="segments-and-relations">
                        <div className="sub-section-title-parent">
                          <div className="sub-section-title">Desirability</div>
                          <img
                            className="frame-icon"
                            alt=""
                            src="./img/frame-5.svg"
                          />
                        </div>
                        <div className="segments-and-relations-items">
                          <div className="segments-and-relations-header">
                            <div className="segments-and-relations-title">
                              <div className="segments-and-relations-content">
                                <div className="segments-and-relations-labels">
                                  <img
                                    className="segment-label-icon"
                                    loading="lazy"
                                    alt=""
                                    src="./img/frame-6.svg"
                                  />

                                  <div className="segment-name">
                                    <div className="customer-segments">
                                      Customer Segments
                                    </div>
                                    <div className="segment-placeholder">
                                      (3)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="segment-add">
                                <div className="create-new">+ Create New</div>
                                <div className="segment-divider"></div>
                              </div>
                              <img
                                className="frame-icon1"
                                alt=""
                                src="./img/frame.svg"
                              />
                            </div>
                            <div className="list">
                              <div className="fitness-fashion-seekers-wrapper">
                                <div className="fitness-fashion-seekers">
                                  Fitness Fashion Seekers
                                </div>
                              </div>
                              <div className="outdoor-adventure-aficionados-wrapper">
                                <div className="outdoor-adventure-aficionados">
                                  Outdoor Adventure Aficionados
                                </div>
                              </div>
                              <div className="team-spirit-supporters-wrapper">
                                <div className="team-spirit-supporters">
                                  Team Spirit Supporters
                                </div>
                              </div>
                              <div className="urban-fitness-trendsetters-wrapper">
                                <div className="urban-fitness-trendsetters">
                                  Urban Fitness Trendsetters
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="propositions">
                            <div className="proposition-items">
                              <div className="proposition-header">
                                <div className="proposition-title">
                                  <img
                                    className="proposition-type-icon"
                                    loading="lazy"
                                    alt=""
                                    src="./img/frame-7.svg"
                                  />

                                  <div className="proposition-name">
                                    <div className="value-proposition">
                                      Value Proposition
                                    </div>
                                    <div className="proposition-placeholder">
                                      (1)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="proposition-add">
                                <div className="create-new1">+ Create New</div>
                                <div className="proposition-divider"></div>
                              </div>
                              <img
                                className="frame-icon2"
                                alt=""
                                src="./img/frame.svg"
                              />
                            </div>
                            <div className="list1">
                              <div className="core-product-is-marty-the-robo-wrapper">
                                <div className="core-product-is">
                                  Core product is Marty the Robot, a smart toy
                                  designed to teach children about coding,
                                  electronics and engineering. Marty is real
                                  robot with arms and legs that users can
                                  customize and programme to perform basic
                                  functions, such as walking, shaking hands and
                                  dancing.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="channel-list-parent">
                            <div className="channel-list">
                              <div className="channel-item">
                                <div className="channel-content">
                                  <img
                                    className="channel-icon"
                                    loading="lazy"
                                    alt=""
                                    src="./img/frame-8.svg"
                                  />

                                  <div className="channel-description">
                                    <div className="channels">Channels</div>
                                    <div className="channel-placeholder">
                                      (1)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="add-channel">
                                <div className="create-new2">+ Create New</div>
                                <div className="add-channel-divider"></div>
                              </div>
                              <img
                                className="frame-icon3"
                                alt=""
                                src="./img/frame.svg"
                              />
                            </div>
                            <div className="list2">
                              <div className="website-channel">
                                <div className="website">Website</div>
                                <div className="develop-a-professional">
                                  Develop a professional and user-friendly
                                  website for the sports campaign. The website
                                  should serve as a central hub for information,
                                  registration, updates, and resources related
                                  to the campaign. Include features such as
                                  event calendars, registration forms, FAQs, and
                                  contact information.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="segments-and-relations-items1">
                          <div className="frame-parent">
                            <div className="frame-wrapper">
                              <div className="frame-group">
                                <img
                                  className="frame-icon4"
                                  loading="lazy"
                                  alt=""
                                  src="./img/frame-9.svg"
                                />

                                <div className="relationship-label">
                                  <div className="customer-relationship">
                                    Customer Relationship
                                  </div>
                                  <div className="relationship-placeholder">
                                    (1)
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="create-new-parent">
                              <div className="create-new3">+ Create New</div>
                              <div className="frame-child"></div>
                            </div>
                            <img
                              className="frame-icon5"
                              alt=""
                              src="./img/frame.svg"
                            />
                          </div>
                          <div className="no-data">
                            <div className="no-data-inner">
                              <div className="marty-the-robot-brings-coding-wrapper">
                                <div className="marty-the-robot">
                                  Marty the Robot brings coding to life,
                                  providing students with an inspiring,
                                  practical and fun introduction to digital
                                  skills for study, work and life. Super easy
                                  for teachers to use with a full range of
                                  lesson plans and activities available on the
                                  Marty website.
                                </div>
                              </div>
                            </div>
                            <div className="frame-container">
                              <img
                                className="frame-icon6"
                                alt=""
                                src="./img/frame-10.svg"
                              />

                              <div className="no-data-please">
                                No Data. Please Create New
                              </div>
                            </div>
                          </div>
                          <div className="list3">
                            <div className="get">
                              <div className="get1">Get</div>
                              <div className="stocking-in-major-supermarkets-parent">
                                <div className="stocking-in-major">
                                  Stocking in major supermarkets
                                </div>
                                <div className="being-stocked-on">
                                  Being stocked on the shelves of major
                                  supermarkets gives customers visibility of the
                                  brand when they go to purchase coffee.
                                </div>
                              </div>
                              <div className="stocking-in-major-supermarkets-group">
                                <div className="stocking-in-major1">
                                  Stocking in major supermarkets
                                </div>
                                <div className="being-stocked-on1">
                                  Being stocked on the shelves of major
                                  supermarkets gives customers visibility of the
                                  brand when they go to purchase coffee.
                                </div>
                              </div>
                              <div className="stocking-in-major-supermarkets-container">
                                <div className="stocking-in-major2">
                                  Stocking in major supermarkets
                                </div>
                                <div className="being-stocked-on2">
                                  Being stocked on the shelves of major
                                  supermarkets gives customers visibility of the
                                  brand when they go to purchase coffee.
                                </div>
                              </div>
                            </div>
                            <div className="keep">
                              <div className="keep1">Keep</div>
                              <div className="stories-on-social-media-parent">
                                <div className="stories-on-social">
                                  Stories on Social Media
                                </div>
                                <div className="sharing-stories-about">
                                  Sharing stories about the impact that
                                  Cafedirect is having on farmers makes
                                  customers feel good and positive about their
                                  purchase.
                                </div>
                              </div>
                              <div className="frame-div">
                                <div className="stocking-in-major3">
                                  Stocking in major supermarkets
                                </div>
                                <div className="being-stocked-on3">
                                  Being stocked on the shelves of major
                                  supermarkets gives customers visibility of the
                                  brand when they go to purchase coffee.
                                </div>
                              </div>
                              <div className="stocking-in-major-supermarkets-parent1">
                                <div className="stocking-in-major4">
                                  Stocking in major supermarkets
                                </div>
                                <div className="being-stocked-on4">
                                  Being stocked on the shelves of major
                                  supermarkets gives customers visibility of the
                                  brand when they go to purchase coffee.
                                </div>
                              </div>
                            </div>
                            <div className="grow">
                              <div className="grow1">Grow</div>
                              <div className="discount-codes-parent">
                                <div className="discount-codes">
                                  Discount codes
                                </div>
                                <div className="customers-get-discounts">
                                  Customers get discounts on orders if they add
                                  a code received from previous orders.
                                </div>
                              </div>
                              <div className="stocking-in-major-supermarkets-parent2">
                                <div className="stocking-in-major5">
                                  Stocking in major supermarkets
                                </div>
                                <div className="being-stocked-on5">
                                  Being stocked on the shelves of major
                                  supermarkets gives customers visibility of the
                                  brand when they go to purchase coffee.
                                </div>
                              </div>
                              <div className="stocking-in-major-supermarkets-parent3">
                                <div className="stocking-in-major6">
                                  Stocking in major supermarkets
                                </div>
                                <div className="being-stocked-on6">
                                  Being stocked on the shelves of major
                                  supermarkets gives customers visibility of the
                                  brand when they go to purchase coffee.
                                </div>
                              </div>
                            </div>
                            <div className="referrals">
                              <div className="referrals1">Referrals</div>
                              <div className="referrals-description">
                                <div className="partnerships-with-businesses">
                                  Partnerships with businesses
                                </div>
                                <div className="businesses-buy-directly">
                                  Businesses buy directly as they want to show
                                  their commitment to ethical business and
                                  buying Cafedirect coffee is a good way to
                                  demonstrate this.
                                </div>
                              </div>
                              <div className="stocking-in-major-supermarkets-parent4">
                                <div className="stocking-in-major7">
                                  Stocking in major supermarkets
                                </div>
                                <div className="being-stocked-on7">
                                  Being stocked on the shelves of major
                                  supermarkets gives customers visibility of the
                                  brand when they go to purchase coffee.
                                </div>
                              </div>
                              <div className="stocking-in-major-supermarkets-parent5">
                                <div className="stocking-in-major8">
                                  Stocking in major supermarkets
                                </div>
                                <div className="being-stocked-on8">
                                  Being stocked on the shelves of major
                                  supermarkets gives customers visibility of the
                                  brand when they go to purchase coffee.
                                </div>
                              </div>
                            </div>
                            <div className="outdoor-adventure-aficionados-container">
                              <div className="outdoor-adventure-aficionados1">
                                Outdoor Adventure Aficionados
                              </div>
                            </div>
                            <div className="team-spirit-supporters-container">
                              <div className="team-spirit-supporters1">
                                Team Spirit Supporters
                              </div>
                            </div>
                            <div className="urban-fitness-trendsetters-container">
                              <div className="urban-fitness-trendsetters1">
                                Urban Fitness Trendsetters
                              </div>
                            </div>
                            <div className="urban-fitness-trendsetters-frame">
                              <div className="urban-fitness-trendsetters2">
                                Urban Fitness Trendsetters
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-parent1">
                        <div className="sub-section-title-group">
                          <div className="sub-section-title1">Feasibility</div>
                          <img
                            className="frame-icon7"
                            alt=""
                            src="./img/frame-11.svg"
                          />
                        </div>
                        <div className="key-activities">
                          <div className="key-activities-header">
                            <div className="key-activities-title">
                              <div className="key-activities-name">
                                <div className="key-activities-label">
                                  <img
                                    className="empty-icon5"
                                    loading="lazy"
                                    alt=""
                                    src="./img/frame-12.svg"
                                  />

                                  <div className="key-activities-value">
                                    <div className="key-activities1">
                                      Key Activities
                                    </div>
                                    <div className="separator">(3)</div>
                                  </div>
                                </div>
                              </div>
                              <div className="key-activities-actions">
                                <div className="create-new4">+ Create New</div>
                                <div className="divider"></div>
                              </div>
                              <img
                                className="frame-icon8"
                                alt=""
                                src="./img/frame.svg"
                              />
                            </div>
                            <div className="list4">
                              <div className="step-1-parent">
                                <div className="step-1">Step 1</div>
                                <div className="collect-discarded-flip-flops">
                                  Collect discarded flip-flops along beaches and
                                  waterways
                                </div>
                              </div>
                              <div className="step-2-parent">
                                <div className="step-2">Step 2</div>
                                <div className="create-designs-for">
                                  Create designs for art made from flip-flops
                                </div>
                              </div>
                              <div className="step-3-parent">
                                <div className="step-3">Step 3</div>
                                <div className="clean-and-sort">
                                  Clean and sort the flip-flops
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="resources">
                            <div className="resource-items">
                              <div className="resource-header">
                                <div className="resource-title">
                                  <img
                                    className="resource-type-icon"
                                    loading="lazy"
                                    alt=""
                                    src="./img/frame-13.svg"
                                  />

                                  <div className="resource-label">
                                    <div className="key-resource">
                                      Key Resource
                                    </div>
                                    <div className="resource-separator">
                                      (2)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="resource-add">
                                <div className="create-new5">+ Create New</div>
                                <div className="resource-divider"></div>
                              </div>
                              <img
                                className="frame-icon9"
                                alt=""
                                src="./img/frame.svg"
                              />
                            </div>
                            <div className="list5">
                              <div className="a-team-with-expertise-in-relev-wrapper">
                                <div className="a-team-with">
                                  A team with expertise in relevant technologies
                                  to design Marty
                                </div>
                              </div>
                              <div className="a-warehouse-to-store-finished-wrapper">
                                <div className="a-warehouse-to">
                                  A warehouse to store finished products
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="partners">
                            <div className="partner-items">
                              <div className="partner-header">
                                <div className="partner-title">
                                  <img
                                    className="partner-type-icon"
                                    alt=""
                                    src="./img/frame-14.svg"
                                  />

                                  <div className="partner-label">
                                    <div className="key-partners">
                                      Key Partners
                                    </div>
                                    <div className="partner-separator">(1)</div>
                                  </div>
                                </div>
                              </div>
                              <div className="partner-add">
                                <div className="create-new6">+ Create New</div>
                                <div className="partner-divider"></div>
                              </div>
                              <img
                                className="frame-icon10"
                                alt=""
                                src="./img/frame.svg"
                              />
                            </div>
                            <div className="list6">
                              <div className="high-quality-manufacturers-to-wrapper">
                                <div className="high-quality-manufacturers">
                                  High quality manufacturers to produce Marty
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-parent2">
                        <div className="sub-section-title-container">
                          <div className="sub-section-title2">Viability</div>
                          <img
                            className="frame-icon11"
                            alt=""
                            src="./img/frame-15.svg"
                          />
                        </div>
                        <div className="frame-parent3">
                          <div className="frame-parent4">
                            <div className="frame-parent5">
                              <div className="frame-wrapper1">
                                <div className="frame-parent6">
                                  <img
                                    className="frame-icon12"
                                    loading="lazy"
                                    alt=""
                                    src="./img/frame-16.svg"
                                  />

                                  <div className="cost-parent">
                                    <div className="cost">Cost</div>
                                    <div className="div">(2)</div>
                                  </div>
                                </div>
                              </div>
                              <div className="create-new-group">
                                <div className="create-new7">+ Create New</div>
                                <div className="frame-item"></div>
                              </div>
                              <img
                                className="frame-icon13"
                                alt=""
                                src="./img/frame.svg"
                              />
                            </div>
                            <div className="list7">
                              <div className="annual-fixed-cost-parent">
                                <div className="annual-fixed-cost">
                                  Annual Fixed Cost
                                </div>
                                <div className="div1">111,220</div>
                              </div>
                              <div className="annual-variable-cost-parent">
                                <div className="annual-variable-cost">
                                  Annual Variable Cost
                                </div>
                                <div className="div2">0</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-parent7">
                            <div className="frame-parent8">
                              <div className="frame-wrapper2">
                                <div className="frame-parent9">
                                  <img
                                    className="frame-icon14"
                                    loading="lazy"
                                    alt=""
                                    src="./img/frame-17.svg"
                                  />

                                  <div className="revenue-parent">
                                    <div className="revenue">Revenue</div>
                                    <div className="div3">(2)</div>
                                  </div>
                                </div>
                              </div>
                              <div className="create-new-container">
                                <div className="create-new8">+ Create New</div>
                                <div className="frame-inner"></div>
                              </div>
                              <img
                                className="frame-icon15"
                                alt=""
                                src="./img/frame.svg"
                              />
                            </div>
                            <div className="list8">
                              <div className="revenue-model-parent">
                                <input
                                  className="revenue-model"
                                  placeholder="Revenue Model"
                                  type="text"
                                />

                                <div className="div4">-</div>
                              </div>
                              <div className="annual-units-sold-parent">
                                <div className="annual-units-sold">
                                  Annual Units Sold
                                </div>
                                <div className="div5">0</div>
                              </div>
                              <div className="annual-revenue-parent">
                                <div className="annual-revenue">
                                  Annual Revenue
                                </div>
                                <div className="div6">0</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-parent10">
                            <div className="frame-parent11">
                              <div className="frame-wrapper3">
                                <div className="frame-parent12">
                                  <img
                                    className="frame-icon16"
                                    alt=""
                                    src="./img/frame-18.svg"
                                  />

                                  <div className="sustainabilty-parent">
                                    <div className="sustainabilty">
                                      Sustainabilty
                                    </div>
                                    <div className="div7">(2)</div>
                                  </div>
                                </div>
                              </div>
                              <div className="create-new-parent1">
                                <a className="create-new9">+ Create New</a>
                                <div className="line-div"></div>
                              </div>
                              <img
                                className="frame-icon17"
                                alt=""
                                src="./img/frame.svg"
                              />
                            </div>
                            <div className="list9">
                              <div className="quality-education-parent">
                                <div className="quality-education">
                                  Quality Education
                                </div>
                                <div className="young-people-need">
                                  Young people need to learn digital skills
                                  necessary to thrive in the future world of
                                  work. However, too often these skills are not
                                  adequately addressed in education systems that
                                  are slow to adapt to changing needs.
                                </div>
                              </div>
                              <div className="good-health-well-being-parent">
                                <div className="good-health">
                                  Good Health & Well Being
                                </div>
                                <div className="through-marty-the">
                                  Through Marty the Robot help teachers deliver
                                  learning experiences that are inclusive,
                                  supportive and fun.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="features-content-wrapper">
          <div className="features-content">
            <div className="features-title">
              <h1 className="powerful-features-to">
                Powerful Features to Fuel Your Business Growth
              </h1>
            </div>
            <div className="canvas-creation">
              <div className="target-customer">
                <div className="customer-name-input">
                  <div className="input-suggestions">
                    <div className="ellipse-parent">
                      <div className="ellipse-div"></div>
                      <img
                        className="name-input-border"
                        alt=""
                        src="./img/name-input-border@2x.png"
                      />
                    </div>
                    <div className="step-11">
                      <div className="input-group">
                        <div className="name-of-your">
                          Name of your target Customer Segment?
                        </div>
                        <div className="input-placeholder">
                          <input
                            className="enter-customer-segment"
                            placeholder="Enter customer segment name"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="ai-suggestions">
                        <div className="suggestion-header">
                          <div className="ai-suggestions1">AI Suggestions</div>
                          <img
                            className="suggestion-dropdown-icon"
                            alt=""
                            src="./img/frame-19.svg"
                          />
                        </div>
                        <div className="suggestion-list">
                          <div className="element-type">
                            <div className="secondary-schools">
                              Secondary schools
                            </div>
                          </div>
                          <div className="ethical-commuters-wrapper">
                            <div className="ethical-commuters">
                              Ethical Commuters
                            </div>
                          </div>
                          <div className="tech-savvy-young-women-wrapper">
                            <div className="tech-savvy-young">
                              Tech savvy young women
                            </div>
                          </div>
                          <div className="independent-high-street-retail-wrapper">
                            <div className="independent-high-street-retail">
                              Independent high-street retailers
                            </div>
                          </div>
                          <div className="busy-health-conscious-parents-wrapper">
                            <div className="busy-health-conscious">
                              Busy, health conscious parents
                            </div>
                          </div>
                          <div className="women-with-afro-hair-wrapper">
                            <div className="women-with-afro">
                              Women with afro hair
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="action">
                        <div className="load-more-suggestions">
                          Load more suggestions
                        </div>
                        <button className="next-button-container">
                          <div className="next">Next</div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="canvas-benefits">
                    <h2 className="create-your-business">
                      Create your Business Model Canvas
                    </h2>
                    <div className="seamlessly-design-iterate">
                      Seamlessly design, iterate, and refine your business
                      strategy to unlock opportunities and drive growth.
                    </div>
                    <div className="get-started-parent">
                      <input
                        className="get-started1"
                        placeholder="Get Started"
                        type="text"
                      />

                      <div className="frame-child1"></div>
                    </div>
                  </div>
                </div>
                <div className="customer-name-input1">
                  <div className="wrapper-group-1000008965-parent">
                    <div className="wrapper-group-1000008965">
                      <img
                        className="wrapper-group-1000008965-child"
                        alt=""
                        src="./img/group-1000008965@2x.png"
                      />
                    </div>
                    <div className="popup-create-test">
                      <div className="frame-parent13">
                        <div className="create-test-parent">
                          <div className="create-test">Create Test</div>
                          <div className="this-test-has-container">
                            <span>This Test has utilize </span>
                            <span className="credits">5 credits</span>
                            <span>.</span>
                          </div>
                        </div>
                        <img
                          className="frame-icon18"
                          alt=""
                          src="./img/frame-20.svg"
                        />
                      </div>
                      <div className="element-selection-parent">
                        <div className="element-selection">
                          <div className="select-business-model">
                            Select business model canvas
                          </div>
                          <div className="frame-frame">
                            <img
                              className="frame-icon19"
                              alt=""
                              src="./img/frame-21.svg"
                            />
                          </div>
                        </div>
                        <div className="element-selection1">
                          <div className="select-element-type">
                            Select element type
                          </div>
                          <div className="frame-wrapper4">
                            <img
                              className="frame-icon20"
                              alt=""
                              src="./img/frame-22.svg"
                            />
                          </div>
                        </div>
                        <div className="element-selection2">
                          <div className="select-element">Select element</div>
                          <div className="element-dropdown">
                            <img
                              className="frame-icon21"
                              alt=""
                              src="./img/frame-23.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="create-test-on-surveymenkeyco-wrapper">
                        <div className="create-test-on-container">
                          Create Test on
                          <span className="surveymenkeycom">
                            surveymenkey.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="validate-your-business-bluepri-parent">
                    <h2 className="validate-your-business">
                      Validate Your Business Blueprint
                    </h2>
                    <div className="validate-your-business1">
                      Validate your business model with our Element Test
                      feature. Gain valuable insights, test assumptions, and
                      refine your strategy.
                    </div>
                    <div className="get-started-group">
                      <input
                        className="get-started2"
                        placeholder="Get Started"
                        type="text"
                      />

                      <div className="frame-child2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="third-party-integration">
                <div className="integration-content">
                  <h2 className="enhance-your-journey">
                    Enhance Your Journey with Exclusive Third-Party Resources
                  </h2>
                  <div className="integration-benefits">
                    <div className="benefit-list">
                      <div className="benefit-item">
                        <input
                          className="benefit-item-child"
                          type="radio"
                          name="radioGroup-1"
                        />
                      </div>
                      <div className="access-a-wide">
                        Access a wide range of industry-leading software
                        integrations.
                      </div>
                    </div>
                    <div className="benefit-list1">
                      <div className="frame">
                        <input
                          className="group-input"
                          type="radio"
                          name="radioGroup-1"
                        />
                      </div>
                      <div className="expand-your-network">
                        Expand your network and discover new opportunities
                        through integrated networking platforms.
                      </div>
                    </div>
                    <div className="benefit-list2">
                      <div className="frame1">
                        <input className="frame-child3" type="radio" />
                      </div>
                      <div className="access-premium-content">
                        Access premium content and educational resources through
                        integrated platforms.
                      </div>
                    </div>
                  </div>
                  <div className="integration-action">
                    <input
                      className="get-started3"
                      placeholder="Get Started"
                      type="text"
                    />

                    <div className="integration-divider"></div>
                  </div>
                </div>
                <div className="branding-content-wrapper">
                  <div className="branding-content">
                    <div className="branding-list">
                      <div className="enhance-your-journey1">
                        Enhance Your Journey with Exclusive Third-Party
                        Resources
                      </div>
                      <div className="list10">
                        <div className="tool-list">
                          <img
                            className="tool-item-icon"
                            loading="lazy"
                            alt=""
                            src="./img/frame-24.svg"
                          />

                          <div className="tool-description">
                            <div className="brand-your-business">
                              Brand Your Business
                            </div>
                            <div className="tools-that-support">
                              Tools that support your growth
                            </div>
                          </div>
                          <img
                            className="tool-icon"
                            alt=""
                            src="./img/frame-25.svg"
                          />
                        </div>
                        <div className="tool-list1">
                          <img
                            className="frame-icon22"
                            loading="lazy"
                            alt=""
                            src="./img/frame-26.svg"
                          />

                          <div className="survey-testing-parent">
                            <div className="survey-testing">
                              Survey & Testing
                            </div>
                            <div className="ab-testing-manual">
                              AB testing, Manual Testing, User testing
                            </div>
                          </div>
                          <img
                            className="frame-icon23"
                            alt=""
                            src="./img/frame-27.svg"
                          />
                        </div>
                        <div className="tool-list2">
                          <img
                            className="frame-icon24"
                            alt=""
                            src="./img/frame-28.svg"
                          />

                          <div className="marketing-parent">
                            <div className="marketing">Marketing</div>
                            <input
                              className="social-growth-b2b"
                              placeholder="Social growth, B2B, B2C, SEO"
                              type="text"
                            />
                          </div>
                          <img
                            className="frame-icon25"
                            alt=""
                            src="./img/frame-29.svg"
                          />
                        </div>
                        <div className="tool-list3">
                          <input className="frame2" type="checkbox" />

                          <div className="web-app-development-parent">
                            <div className="web-app">Web & App Development</div>
                            <div className="website-mobile-application">
                              Website, mobile application for bussiness
                            </div>
                          </div>
                          <img
                            className="frame-icon26"
                            alt=""
                            src="./img/frame-31.svg"
                          />
                        </div>
                        <div className="tool-list4">
                          <img
                            className="frame-icon27"
                            alt=""
                            src="./img/frame-32.svg"
                          />

                          <div className="manage-database-parent">
                            <div className="manage-database">
                              Manage Database
                            </div>
                            <input
                              className="server-maintainence-tech"
                              placeholder="Server maintainence, Tech support"
                              type="text"
                            />
                          </div>
                          <img
                            className="frame-icon28"
                            alt=""
                            src="./img/frame-33.svg"
                          />
                        </div>
                        <div className="tool-list5">
                          <img
                            className="frame-icon29"
                            loading="lazy"
                            alt=""
                            src="./img/frame-34.svg"
                          />

                          <div className="funding-opportunities-parent">
                            <div className="funding-opportunities">
                              Funding Opportunities
                            </div>
                            <div className="business-may-be">
                              Business may be eligible for funding
                            </div>
                          </div>
                          <img
                            className="frame-icon30"
                            alt=""
                            src="./img/frame-35.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="branding-popup">
                  <div className="popup-trigger"></div>
                  <div className="popup">
                    <div className="popup-content">
                      <div className="brand-your-business1">
                        Brand Your Business
                      </div>
                      <div className="brand-container">
                        <img
                          className="brand-icon"
                          alt=""
                          src="./img/frame-36.svg"
                        />
                      </div>
                    </div>
                    <div className="sportwear-container">
                      <div className="sportwear-label">
                        <a className="sporst-wear">Sporst Wear</a>
                        <img
                          className="sportwear-icon"
                          alt=""
                          src="./img/frame-37.svg"
                        />
                      </div>
                      <div className="tools-container">
                        <div className="tools-brand">
                          <div className="tools-list">
                            <div className="tool">
                              <img
                                className="figma-symbolsvg-icon"
                                alt=""
                                src="./img/figma-symbolsvg.svg"
                              />

                              <img
                                className="figma-logosvg-icon"
                                loading="lazy"
                                alt=""
                                src="./img/figma-logosvg.svg"
                              />
                            </div>
                            <div className="figma-is-a">
                              Figma is a design platform built for
                              collaboration. It lets you and your team work on
                              designs simultaneously, in real-time. With its
                              intuitive interface, you can transform your design
                              ideas into reality and ensure everyone is on the
                              same page.
                            </div>
                            <div className="get-started4">Get Started</div>
                          </div>
                          <div className="tools-list1">
                            <img
                              className="group-icon"
                              loading="lazy"
                              alt=""
                              src="./img/group.svg"
                            />

                            <div className="fiverr-is-global">
                              Fiverr is global online marketplace offering tasks
                              and services, beginning at a cost of $5 per job.
                              It connects businesses with freelancers offering
                              digital services in categories like graphics &
                              design, marketing, writing, programming, and more.
                            </div>
                            <div className="get-started5">Get Started</div>
                          </div>
                          <div className="tools-list2">
                            <div className="framer-logosvg">
                              <img
                                className="group-icon1"
                                loading="lazy"
                                alt=""
                                src="./img/group-1.svg"
                              />

                              <div className="group-wrapper">
                                <img
                                  className="group-icon2"
                                  loading="lazy"
                                  alt=""
                                  src="./img/group-2.svg"
                                />
                              </div>
                            </div>
                            <div className="a-free-tool">
                              A free tool for UI and UX design Framer is a free
                              platform that allows you to create realistic UI
                              and UX designs, for any type of website, that are
                              interactive from the start.
                            </div>
                            <div className="get-started6">Get Started</div>
                          </div>
                          <div className="tools-list3">
                            <img
                              className="webflow-logosvg-icon"
                              loading="lazy"
                              alt=""
                              src="./img/webflow-logosvg.svg"
                            />

                            <div className="create-custom-responsive">
                              Create custom, responsive websites with the power
                              of code — visually. Design and build your site
                              with a flexible CMS and top-tier hosting.
                            </div>
                            <div className="get-started7">Get Started</div>
                          </div>
                        </div>
                        <div className="tools-divider"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="canbiz-landing-page-desktop-inner">
          <div className="frame-parent14">
            <div className="discover-canbiz-transform-yo-wrapper">
              <h1 className="discover-canbiz">
                Discover CanBiz & Transform Your Vision into Reality
              </h1>
            </div>
            <div className="content-parent">
              <div className="content">
                <img
                  className="user-friendly-interface"
                  loading="lazy"
                  alt=""
                  src="./img/user-friendly-interface.svg"
                />

                <div className="feature-list">
                  <div className="user-friendly-interface1">
                    User-Friendly Interface
                  </div>
                </div>
              </div>
              <div className="content1">
                <img
                  className="comprehensive-features-icon"
                  loading="lazy"
                  alt=""
                  src="./img/comprehensive-features.svg"
                />

                <div className="comprehensive-features-wrapper">
                  <div className="comprehensive-features">
                    Comprehensive Features
                  </div>
                </div>
              </div>
              <div className="content2">
                <img
                  className="collaborative-platform-icon"
                  alt=""
                  src="./img/collaborative-platform.svg"
                />

                <div className="collaborative-platform-wrapper">
                  <div className="collaborative-platform">
                    Collaborative Platform
                  </div>
                </div>
              </div>
              <div className="content3">
                <img
                  className="customer-centric-approach-icon"
                  loading="lazy"
                  alt=""
                  src="./img/customercentric-approach.svg"
                />

                <div className="customer-centric-approach-wrapper">
                  <div className="customer-centric-approach">
                    Customer-Centric Approach
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-image">
              <div className="dashboard-image-child"></div>
              <div className="dashboard-container">
                <div className="ellipse-group">
                  <div className="frame-child4"></div>
                  <img
                    className="canbiz-dashboard-grid-view"
                    loading="lazy"
                    alt=""
                    src="./img/canbiz--dashboard--grid-view-1@2x.png"
                  />

                  <div className="grid-image"></div>
                </div>
              </div>
            </div>
            <div className="entrepreneurship">
              <div className="we-believe-in">
                We believe in the power of entrepreneurship to drive innovation
                and change. Founded with a vision to empower aspiring
                entrepreneurs and businesses of all sizes, CanBiz provides
                intuitive tools and resources to turn ideas into reality. Our
                mission is to democratize entrepreneurship by making essential
                business tools accessible to everyone. Whether you're a seasoned
                entrepreneur or just starting your journey, CanBiz is here to
                support you every step of the way.
              </div>
            </div>
          </div>
        </section>
        <section className="credit-content-wrapper">
          <div className="credit-content">
            <div className="credit-heading">
              <h1 className="affordable-flexible">
                Affordable & Flexible Credit Plans for Every Need
              </h1>
            </div>
            <div className="credit-plans">
              <div className="credit-plan">
                <div className="plan-details"></div>
                <div className="credit-purchase">
                  <div className="purchase-details">
                    <div className="purchase-button">
                      <div className="wrapper-purchase-icon">
                        <img
                          className="purchase-icon"
                          loading="lazy"
                          alt=""
                          src="./img/frame-38.svg"
                        />
                      </div>
                    </div>
                    <div className="credit-amount">
                      <div className="credit-display">
                        <div className="credit-count">
                          <div className="amount-separator">03</div>
                          <div className="credits1">Credits</div>
                        </div>
                      </div>
                      <div className="add-credit">
                        <b className="add-separator">$5.00</b>
                      </div>
                    </div>
                  </div>
                  <div className="buy-credit-button">
                    <button className="buy-credit-container">
                      <div className="buy-credits">Buy Credits</div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="credit-plan1">
                <div className="credit-info-parent">
                  <div className="credit-info">
                    <div className="wrapper-credit-icon">
                      <img
                        className="credit-icon"
                        loading="lazy"
                        alt=""
                        src="./img/frame-39.svg"
                      />
                    </div>
                  </div>
                  <div className="credit-name">
                    <div className="credit-label">
                      <div className="credit-value">
                        <div className="separator1">05</div>
                        <div className="credits2">Credits</div>
                      </div>
                    </div>
                    <div className="credit-button">
                      <b className="button-separator">$20.00</b>
                    </div>
                  </div>
                </div>
                <div className="credit-plan-inner">
                  <button className="buy-credits-wrapper">
                    <div className="buy-credits1">Buy Credits</div>
                  </button>
                </div>
              </div>
              <div className="credit-plan2">
                <div className="frame-parent15">
                  <div className="frame-wrapper5">
                    <div className="frame3">
                      <img
                        className="vector-icon"
                        alt=""
                        src="./img/vector.svg"
                      />

                      <img
                        className="vector-icon1"
                        alt=""
                        src="./img/vector-1.svg"
                      />

                      <img
                        className="vector-icon2"
                        alt=""
                        src="./img/vector-2.svg"
                      />

                      <img
                        className="vector-icon3"
                        alt=""
                        src="./img/vector-3.svg"
                      />

                      <img
                        className="frame-child5"
                        alt=""
                        src="./img/group-1000008974.svg"
                      />

                      <img
                        className="frame-child6"
                        loading="lazy"
                        alt=""
                        src="./img/group-1000008972.svg"
                      />

                      <img
                        className="frame-child7"
                        alt=""
                        src="./img/group-1000008973@2x.png"
                      />
                    </div>
                  </div>
                  <div className="frame-parent16">
                    <div className="frame-wrapper6">
                      <div className="parent">
                        <div className="div8">10</div>
                        <div className="credits3">Credits</div>
                      </div>
                    </div>
                    <div className="wrapper">
                      <b className="b">$35.00</b>
                    </div>
                  </div>
                </div>
                <div className="credit-plan-child">
                  <button className="buy-credits-container">
                    <div className="buy-credits2">Buy Credits</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="customer-review">
          <h1 className="hear-from-our">Hear from Our Happy Customers</h1>
          <div className="customer-review-list">
            <div className="review">
              <div className="content4">
                <div className="canbiz-has-been">
                  CanBiz has been a game-changer for my startup journey. It's
                  incredibly intuitive and helped me map out my business model
                  effortlessly.
                </div>
                <div className="information">
                  <div className="sarah-h">Sarah H</div>
                  <div className="startup-founder">Startup Founder</div>
                  <div className="stars">
                    <img
                      className="review-star-icon"
                      alt=""
                      src="./img/frame-40.svg"
                    />

                    <img
                      className="review-star-icon1"
                      alt=""
                      src="./img/frame-41.svg"
                    />

                    <img
                      className="review-star-icon2"
                      alt=""
                      src="./img/frame-42.svg"
                    />

                    <img
                      className="review-star-icon3"
                      alt=""
                      src="./img/frame-43.svg"
                    />

                    <img
                      className="review-star-icon4"
                      alt=""
                      src="./img/frame-44.svg"
                    />
                  </div>
                </div>
              </div>
              <img
                className="review-child"
                loading="lazy"
                alt=""
                src="./img/group-1000008968@2x.png"
              />
            </div>
            <div className="review1">
              <div className="content5">
                <div className="canbiz-has-been1">
                  CanBiz has been a game-changer for my startup journey. It's
                  incredibly intuitive and helped
                </div>
                <div className="information1">
                  <div className="stars1">
                    <img
                      className="frame-icon31"
                      alt=""
                      src="./img/frame-45.svg"
                    />

                    <img
                      className="frame-icon32"
                      alt=""
                      src="./img/frame-46.svg"
                    />

                    <img
                      className="frame-icon33"
                      alt=""
                      src="./img/frame-46.svg"
                    />

                    <img
                      className="frame-icon34"
                      alt=""
                      src="./img/frame-46.svg"
                    />

                    <img
                      className="frame-icon35"
                      alt=""
                      src="./img/frame-49.svg"
                    />
                  </div>
                  <div className="reviewer-details">
                    <div className="michael-t">Michael T</div>
                    <div className="small-business-owner">
                      Small Business Owner
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="review-item"
                loading="lazy"
                alt=""
                src="./img/group-1000008968-1@2x.png"
              />
            </div>
            <div className="review2">
              <div className="content6">
                <div className="ive-tried-several">
                  I've tried several business model tools, but CanBiz stands out
                  for its simplicity and robust features. Highly recommend!
                </div>
                <div className="information2">
                  <div className="stars2">
                    <img
                      className="frame-icon36"
                      alt=""
                      src="./img/frame-45.svg"
                    />

                    <img
                      className="frame-icon37"
                      alt=""
                      src="./img/frame-51.svg"
                    />

                    <img
                      className="frame-icon38"
                      alt=""
                      src="./img/frame-52.svg"
                    />

                    <img
                      className="frame-icon39"
                      alt=""
                      src="./img/frame-52.svg"
                    />

                    <img
                      className="frame-icon40"
                      alt=""
                      src="./img/frame-54.svg"
                    />
                  </div>
                  <div className="emily-s-parent">
                    <div className="emily-s">Emily S</div>
                    <a className="entrepreneur">Entrepreneur</a>
                  </div>
                </div>
              </div>
              <img
                className="review-inner"
                loading="lazy"
                alt=""
                src="./img/group-1000008968-1@2x.png"
              />
            </div>
            <div className="review3">
              <div className="content7">
                <div className="canbiz-helped-me">
                  CanBiz helped me validate my business idea and refine my
                  strategy. The Element Tests were invaluable in understanding
                  my market.
                </div>
                <div className="information3">
                  <div className="stars3">
                    <img
                      className="frame-icon41"
                      alt=""
                      src="./img/frame-45.svg"
                    />

                    <img
                      className="frame-icon42"
                      alt=""
                      src="./img/frame-46.svg"
                    />

                    <img
                      className="frame-icon43"
                      alt=""
                      src="./img/frame-46.svg"
                    />

                    <img
                      className="frame-icon44"
                      alt=""
                      src="./img/frame-46.svg"
                    />

                    <img
                      className="frame-icon45"
                      alt=""
                      src="./img/frame-49.svg"
                    />
                  </div>
                  <div className="david-l-parent">
                    <div className="david-l">David L</div>
                    <div className="consultant">Consultant</div>
                  </div>
                </div>
              </div>
              <img
                className="review-child1"
                loading="lazy"
                alt=""
                src="./img/group-1000008968-1@2x.png"
              />
            </div>
            <div className="review4">
              <div className="content8">
                <div className="as-a-business">
                  As a business consultant, I rely on CanBiz to collaborate with
                  clients and visualize their business models. It's a must-have
                  tool in my arsenal.
                </div>
                <div className="information4">
                  <div className="stars4">
                    <img
                      className="frame-icon46"
                      alt=""
                      src="./img/frame-60.svg"
                    />

                    <img
                      className="frame-icon47"
                      alt=""
                      src="./img/frame-61.svg"
                    />

                    <img
                      className="frame-icon48"
                      alt=""
                      src="./img/frame-61.svg"
                    />

                    <img
                      className="frame-icon49"
                      alt=""
                      src="./img/frame-61.svg"
                    />

                    <img
                      className="frame-icon50"
                      alt=""
                      src="./img/frame-64.svg"
                    />
                  </div>
                  <div className="jasmine-m-parent">
                    <div className="jasmine-m">Jasmine M</div>
                    <div className="marketing-professional">
                      Marketing Professional
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="review-child2"
                loading="lazy"
                alt=""
                src="./img/group-1000008968-1@2x.png"
              />
            </div>
            <div className="review5">
              <div className="content9">
                <div className="even-as-a">
                  Even as a student entrepreneur, CanBiz has been instrumental
                  in shaping my business ideas. The user-friendly interface
                  makes it accessible for beginners like me.
                </div>
                <div className="information5">
                  <div className="stars5">
                    <img
                      className="frame-icon51"
                      alt=""
                      src="./img/frame-60.svg"
                    />

                    <img
                      className="frame-icon52"
                      alt=""
                      src="./img/frame-61.svg"
                    />

                    <img
                      className="frame-icon53"
                      alt=""
                      src="./img/frame-61.svg"
                    />

                    <img
                      className="frame-icon54"
                      alt=""
                      src="./img/frame-61.svg"
                    />

                    <img
                      className="frame-icon55"
                      alt=""
                      src="./img/frame-64.svg"
                    />
                  </div>
                  <div className="alex-b-parent">
                    <div className="alex-b">Alex B</div>
                    <a className="student-entrepreneur">Student Entrepreneur</a>
                  </div>
                </div>
              </div>
              <img
                className="review-child3"
                loading="lazy"
                alt=""
                src="./img/group-1000008968-1@2x.png"
              />
            </div>
            <div className="review-item1"></div>
            <div className="review-item2"></div>
          </div>
        </section>
        <section className="container">
          <div className="footer-top">
            <div className="footer-content">
              <img
                className="footer-content-child"
                loading="lazy"
                alt=""
                src="./img/group-1000008967.svg"
              />

              <div className="footer-ellipsis"></div>
            </div>
            <div className="footer-bottom">
              <div className="success-container">
                <div className="success-message">
                  <h1 className="start-building-your">
                    Start Building Your Success Story Today
                  </h1>
                  <div className="take-the-first">
                    Take the first step towards entrepreneurial success. Sign up
                    now to unlock powerful tools and resources with CanBiz.
                  </div>
                </div>
                <button className="footer-button">
                  <div className="get-started8">Get Started</div>
                </button>
              </div>
            </div>
          </div>
        </section>
        <footer className="header1">
          <div className="header-child"></div>
          <div className="frame-parent17">
            <div className="canbiz-all-rights-reserved-wrapper">
              <div className="canbiz-all-rights">
                © 2024 Canbiz. All rights reserved.
              </div>
            </div>
            <div className="button-container">
              <img
                className="demo-button-icon"
                loading="lazy"
                alt=""
                src="./img/frame-70.svg"
              />

              <img
                className="login-button-icon"
                loading="lazy"
                alt=""
                src="./img/frame-71.svg"
              />

              <img
                className="empty-button-icon"
                loading="lazy"
                alt=""
                src="./img/frame-72.svg"
              />
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Home;
